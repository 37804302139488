<template>
    <main id="forgottenPsw">
        <Header />
        <div class="hero-modal-page  d-flex justify-content-center align-items-center">
            <div class="hero-closed-gradient"></div>
            <div class="hero-closed-container container-xl">
                <!-- TITLE -->
                <div class="row">                    
                    <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                          <div class="card bg-white form-inner-container form-inner-container-shadow ps-4 pe-4 ps-xl-5 pe-xl-5">
                                <div class="card-dialog">
                                    <div class="card-content text-center">                                            
                                        <img 
                                            src="/imgs/new-password.svg" 
                                            class="img-fluid mb-4" 
                                            alt="új jelszó"
                                        >
                                        <h5 class="modal-title fs-3 fw-bold text-center w-100">Új jelszó</h5>
                                        <ValidationObserver 
                                            v-if="mode == 'normal'" 
                                            ref="observer" 
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form @submit.prevent="handleSubmit(onSubmit)">
                                                <p class="text-center">
                                                    Add meg új jelszavadat
                                                </p>
                                                <CustomInput
                                                    type="password"
                                                    class="mt-4 mb-2"
                                                    :label="'Új jelszó'"
                                                    name="newpass_password"
                                                    v-model="form.password"
                                                    rules="required"
                                                />
                                                <CustomInput
                                                    type="password"
                                                    class="mb-4"
                                                    :label="'Új jelszó még egyszer'"
                                                    name="newpass_pswwconf"
                                                    v-model="form.passconf"
                                                    rules="required|confirmed:newpass_password"
                                                />
                                                <button 
                                                    type="submit" 
                                                    class="btn btn-primary "
                                                >
                                                    Új jelszó mentése
                                                </button>
                                                <div class="alert mb-0" :class="alertType">{{submitMessage}}</div>
                                            </form>
                                        </ValidationObserver>
                                        <div v-if="mode == 'success'">
                                            Sikeresen módosítottad jelszavad
                                        </div>
                                    </div>
                                </div>
                            </div>    
                    </div>
                </div>
            </div>
        </div>          
        <Footer />
    </main>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'

export default {
    components: {        
        CustomInput,
        ValidationObserver,
        Header,
        Footer
    },
    data(){
        return{
            mode: "normal",
            form:{
                password: '',
                passconf: ''
            },
            submitMessage: '',
            alertType:'',
            modal:null 
        }
    },
    methods:{   
        onSubmit(){            
            
            this.post('forgottenpassword/changepassword',
                {
                    forgotten_token: this.$route.params.secret, 
                    password: this.form.password,
                    passconf: this.form.passconf 
            }).then(()=>{
               this.submitMessage = 'Sikeres jelszó módosítás'
               this.mode="success"
               this.alertType = 'alert-success'
            }).catch(()=>{
               this.submitMessage = 'Sikertelen módosítás'
               this.alertType = 'alert-warning'
            })
            
        },
        
    }    
   
}
</script>